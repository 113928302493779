<template >
  <div class="cntsTitle2">
    &bull; 이 시각 주요뉴스
  </div>
  <div class="news" >
    <template v-if="load==true">
      <template v-for="(item, index) in dataList" v-bind:key="index">
        <table class="newsTable">
          <template v-if="typeof item  !== 'undefined'">
            <template v-for='j in parseInt(item.loofCnt)' v-bind:key="j">
            <tr>
              <td class="newsType" v-if="j==1" :rowspan=item.loofCnt>
                {{item.name}}
              </td>
              <td class="newsTitle">
                  &bull; {{ item.datas[j-1].title }}
                  <ld class="writeTime"> {{ (new Date(item.datas[j-1].pubDate)).toLocaleTimeString()    }}</ld>
              </td>
            </tr>
            </template>
          </template>

        </table>
      </template>
    </template>


  </div>
</template>

<script>
import axios from "axios";
import xmlParser from "fast-xml-parser";

export default {
  name: 'TimeCompo',
  components: {},
  props: {},
  data() {
    return {
      load: false,
      dataList: [],
    }
  },
  methods:{


  },
  created() {
    var news = [
        {url:"https://www.yonhapnewstv.co.kr/category/news/headline/feed/", loofCnt:5, name:"연합뉴스"}
      // ,{url:"https://fs.jtbc.co.kr//RSS/newsflash.xml", loofCnt:5, name:"JTBC"}
      ,{url:"http://rss.etnews.com/04.xml", loofCnt:5, name:"전자신문"}
      // ,{url:"http://www.ilovepc.co.kr/rss/allArticle.xml", loofCnt:5, name:"PC사랑"}
      // ,{url:"https://www.khan.co.kr/rss/rssdata/sksports_news.xml", loofCnt:4, name:"스포츠"}
      ,{url:"http://www.ksdaily.co.kr/rss/allArticle.xml", loofCnt:3, name:"증권"}
    ];


    news.forEach((item, i) => {
      axios.get(this.$parent.serverURL + this.$parent.getURLEncode(item.url), {
        headers: {
          "Content-Type": "application/xml",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
        },
      })
    .then(response => {
      const parser = new xmlParser.XMLParser();
      var xml = response.data.replaceAll("<BR>", "").replaceAll("<br>", "").replaceAll("dc:date", "pubDate")
      var data = parser.parse(xml)
      // console.log(data);

      this.dataList[i] = {
        name: item.name,
        datas: data.rss.channel.item,
        loofCnt: item.loofCnt
      };
      // console.log(data)
    });
    })



    this.load = true



  }



}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.cntsTitle2 {
  font-size: 17px;
  margin-top: 30px;

  margin-left: 10px;
  text-align: left;
  font-weight: bold;
  /*border-top: 1px #888;*/
  /*border-top-style: dashed ;*/
  /*width: 100px;*/

}

.newsTable {
  width: 100%;
  height: 60px;
  /*border-bottom: 1px solid #222222;*/
  padding-bottom: 5px;
  margin-top: 10px;
}

.newsTable .newsType {
  width: 15%;
  font-size: 17px;
  text-align: center;
  font-weight: bold;

}

.newsTable .newsTitle {
  font-size: 17px;
  text-align: left;
}

.newsTable .newsTitle .writeTime {
  width: 300px;
  font-size: 14px;
  color: #767676;
  margin-left: 20px;
}


</style>

<template>
    <div class="wallboard">
      <div class="main">
        <div class="lcnts">
          <DateTime msg="DateTime"/>

          <Weather class="weather" msg="Welcome to Your Vue.js App"/>
          <News class="news" msg="Welcome to Your Vue.js App"/>

            <div class="toWeek"> {{ startWeek }}~ {{ endWeek }}</div>
            <div class="lcnts-left">
              <SchedulePublic msg="Welcome to Your Vue.js App" />
            </div>
            <div class="lcnts-right">
              <SchedulePrivate msg="Welcome to Your Vue.js App" />
            </div>
        </div>
<!--        <div class="rcnts">-->
<!--          <ExchangeRadeCompo msg="Welcome to Your Vue.js App" />-->
<!--        </div>-->
      </div>
      <div class="footer">
        <Footer msg="Welcome to Your Vue.js App" />
      </div>

    </div>

</template>

<script>
// @ is an alias to /src
import DateTime from '@/components/TimeCompo.vue'
import Weather from '@/components/WeatherCompo.vue'
import News from '@/components/NewsCompo.vue'
import SchedulePublic from '@/components/SchedulePublicCompo.vue'
import SchedulePrivate from '@/components/SchedulePrivateCompo.vue'
// import ExchangeRadeCompo from '@/components/ExchangeRadeCompo.vue'
import Footer from '@/components/footer.vue'

export default {
  name: 'HomeView',
  components: {
    Weather,
    DateTime,
    News,
    SchedulePublic,
    SchedulePrivate,
    // ExchangeRadeCompo,
    Footer
  },
  data(){
    return{
      serverURL:"http://p.datau.co.kr:2030/rest/",
      startWeek : "",
      serverStockURL:"http://p.datau.co.kr:2030/stock/",
      serverStockTotURL:"http://p.datau.co.kr:2030/stocktot",
      endWeek:"",
      interval:"",
      backColor:"",
      backgroundColor:['#ACF3FF','#B0F7FF','#B4FBFF','#B9FFFF','#C0FFFF','#32F1FF','#3CFBFF','#46FFFF','#96FFFF','#C8FFFF','#00D7FF','#00E1FF','#00EBFF','#00F5FF','#00FFFF','#93DAFF','#98DFFF','#9DE4FF','#A2E9FF','#A7EEFF','#00BFFF'
        ,'#0AC9FF','#14D3FF','#1EDDFF','#28E7FF','#00A5FF','#00AFFF','#00B9FF','#00C3FF','#00CDFF','#BECDFF','#C8D7FF','#D2E1FF','#DCEBFF','#E8F5FF','#90AFFF','#9AB9FF','#A4C3FF','#AECDFF','#B8D7FF','#6495ED','#6E9FED'
        ,'#78A9ED','#82B3ED','#8CBDED','#96C7ED','#A0D1F7','#AADBFF','#B4E5FF','#BEEFFF','#0078FF','#0A82FF','#148CFF','#1E96FF','#28A0FF','#32AAFF','#3CB4FF','#46BEFF','#50C8FF','#5AD2FF','#0078FF','#0A82FF','#148CFF'
        ,'#1E96FF','#28A0FF']
    }
  },
  methods:{
    getURLEncode:function(plainURL){
      var param = btoa(plainURL)
      param = encodeURI(param)
      return param
    },
    getThisWeek: function () {
      var value = [];
      var now = new Date();
      var nowDayOfWeek = now.getDay();
      var nowDay = now.getDate();
      var nowMonth = now.getMonth();
      var nowYear = now.getYear();
      nowYear += (nowYear < 2000) ? 1900 : 0;
      var weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
      var weekEndDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek));

      value.push(weekStartDate);
      value.push(weekEndDate);

      return value;
    },
    methods:{
      pageReflash: function() {
        console.log("conunt")

      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.$router.go();
    }, 3600000)
  },
  created() {

    var toWeek = this.getThisWeek()
    this.startWeek = toWeek[0].getFullYear() + "." + (toWeek[0].getMonth()+1) + "." + toWeek[0].getDate()
    this.endWeek = toWeek[1].getFullYear() + "." + (toWeek[1].getMonth()+1) + "." + toWeek[1].getDate()
    this.backColor = "background-color: " + this.backgroundColor[0]
    console.log("created() {");
  }

}

</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body, html {
  height: 100vh;
}


/*h3 {*/
/*  margin: 40px 0 0;*/
/*}*/
/*ul {*/
/*  list-style-type: none;*/
/*  padding: 0;*/
/*}*/
/*li {*/
/*  display: inline-block;*/
/*  margin: 0 10px;*/
/*}*/
/*a {*/
/*  color: #42b983;*/
/*}*/
.clearfix { clear: both; content: ''; display: block }

.wallboard{
  /*  width: 1024px;*/
  /*height: 100vh;*/
}

.wallboard .main{
  background-color: white;
  margin-top : 5px;

}
.wallboard .main:after{ clear: both; display: block; content: '' }

.wallboard .main .lcnts{
  float: left;
  width: 100%;
  /*height: 768px;*/
  /*border-right: 2px solid #ddd;*/
}


.wallboard .main .lcnts .toWeek{
  padding-top: 30px;
  font-size: 16px;
}


.wallboard .main .lcnts .lcnts-left{
  float: left;
  width: 49%;
  /*height:400px;*/
  padding-top: 15px;
}

.wallboard .main .lcnts .lcnts-right{
  float: left;
  width: 49%;
  /*height:400px;*/
  padding-top: 15px;

}

.wallboard .main .lcnts .otherZone{
}

.wallboard .main .rcnts{
  float: left;
  width: 49%;
}




.wallboard .footer{
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ddd;
}
</style>


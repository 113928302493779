<template>
  <div class="cntsTitle">
    &bull; 오늘의 날씨
  </div>
  <div class="weather">
<!--    <div class="toDayWeather">-->
<!--      <div class="toDayIcon">-->
<!--        <img v-bind:src="toDayWeatherIcon" class="toDayImgIcon">-->
<!--      </div>-->
<!--      <div class="toDayTemp">-->
<!--        {{ toDayWeather.temp }} &#8451;-->
<!--      </div>-->
<!--    </div>-->
    <div class="weekWeather">
      <template v-for="(item, i) in weekWeatherData" v-bind:key="i">
          <div class="weekItem">
              <div class="weekItemTitle" >
                {{ (new Date(item.tmEf)).getDate() }}일 ({{ days[(new Date(item.tmEf)).getDay()] }})
              </div>
              <div class="weekItemImg">
                <img v-bind:src="getImgUrl(weatherIconNum.get(item.wf))"  class="weekItemIcon">
              </div>
            <div class="weekItemText">
              {{ item.wf }}
            </div>
              <div class="weekItemTemp">
                <template v-if="i==0">
                  {{ item.tmx }}&#8451;
                </template>
                <template v-if="i>0">
                  {{ item.tmn }}&#8451;/{{ item.tmx }}&#8451;
                </template>
              </div>
          </div>
        </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import xmlParser from 'fast-xml-parser'
export default {
  name: 'WallBoardMain',
  components: {
  },
  props: {
    msg: String
  },
  data(){
    return{
      url:[
          "https://www.kma.go.kr/wid/queryDFSRSS.jsp?zone=1156054000",
          "https://www.kma.go.kr/weather/forecast/mid-term-rss3.jsp?stnId=109"
      ],
      toDayWeather: "",
      toDayWeatherIcon:"",
      toDayWf:"",
      weekWeatherData: [],
      days : [
        '일요일',
        '월요일',
        '화요일',
        '수요일',
        '목요일',
        '금요일',
        '토요일'
      ],
      weatherIconNum : new Map([
        ["맑음", 1],
        ["구름조금", 2],
        ["구름많음",3],
        ["구름 많음",3],
        ["흐림",4 ],
        ["비",5],
        ["눈",6],
        ["비 또는 눈",7 ],
        ["눈 또는 비",8],
        ["낙뢰",9 ],
        ["연무",10],
        ["안개",11],
        ["황사",12 ],
        ["맑음2",13],
        ["구름조금2",14 ],
        ["구름많음2",15],
        ["소나기",16],
        ["가끔 비, 한때 비",17],
        ["가끔 눈, 한때 눈",18],
        ["빗방울",19],
        ["눈날림",20 ],
        ["박무 (엷은 안개)",21],
        ["undefined",23],
        ["흐리고 비",24],

      ])

    }
  },
  methods:{
    getImgUrl:function(iconNumber) {
      if(iconNumber == undefined){
        var images = require.context('../assets/', false, /\.png$/)
        return images("./23.png")
      }else{
        var images1 = require.context('../assets/', false, /\.png$/)
        return images1('./' + iconNumber + ".png")
      }

    }
  },
  async created () {

    axios.get(this.$parent.serverURL + this.$parent.getURLEncode(this.url[0]),
        {
      headers: {
        "Content-Type": "application/xml",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        "Access-Control-Allow-Credentials": true,
      },
          crossdomain: true,
          withCredentials: false
        }
     )
    .then(response => {
      // console.log(response.data);
      const parser = new xmlParser.XMLParser();
      var data = parser.parse(response.data)
      // console.log(data.rss.channel.item.description.body)

      var today = new Date();
      if(data.rss == undefined){
        return true
      }
      console.log("RSS : " + data.rss)
      for (var i = 0; i < data.rss.channel.item.description.body.data.length; i++) {
        var dataItem;
        dataItem = data.rss.channel.item.description.body.data[i]
        if(i == 0){
          this.weekWeatherData.push({
            tmEf:  new Date(),
            wf:dataItem.wfKor,
            tmn:dataItem.tmn,
            tmx:dataItem.tmx
          })
        }

        else if(dataItem.day > 0 && dataItem.hour == 12){
          var today1 = new Date();
          this.weekWeatherData.push({
            tmEf: new Date(today1.setDate(today.getDate() + dataItem.day)),
            wf:dataItem.wfKor,
            tmn:dataItem.tmn,
            tmx:dataItem.tmx
          })
        }

      }

      //console.log(this.weekWeatherData)


    });
    axios.get(this.$parent.serverURL + this.$parent.getURLEncode(this.url[1]),
        {
      headers: {
        "Content-Type": "application/xml",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        "Access-Control-Allow-Credentials": true,
      },
    })
        .then(response => {
          const parser = new xmlParser.XMLParser();
          var xml = response.data.replaceAll("", "")
          var data = parser.parse(xml)
          if(data.rss == undefined){
            return true
          }
          for (var i = 0; i < data.rss.channel.item.description.body.location[0].data.length; i++) {
            var item = data.rss.channel.item.description.body.location[0].data[i]
            var itemDate = new Date(item.tmEf);
            if(this.weekWeatherData.length < 7 && itemDate.getHours() == 12){
              this.weekWeatherData.push({
                tmEf: itemDate,
                wf:item.wf,
                tmn:item.tmn,
                tmx:item.tmx
              })
            }
            // console.log(item);


          }
          // this.weekWeatherData.push(data.rss.channel.item.description.body.location[0])
          // console.log(weatherIconNum.get("맑음"))

          // console.log(this.weekWeatherData.data)
        });
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cntsTitle{
  font-size: 17px;
  margin-left: 10px;
  text-align: left;
  font-weight: bold;

  /*border-bottom: 3px #888;*/
  /*border-bottom-style: double ;*/
  /*width: 100px;*/

}

.weather{
  /*border-bottom: 1px solid #ccc;*/

}

.weather .toDayWeather{
  height: 150px;
}

.weather .toDayWeather .toDayIcon{
  float: top;
}

.weather .toDayWeather .toDayIcon .toDayImgIcon{
  width: 80px;
  margin-top: 5px;
}



.weather .toDayWeather .toDayTemp{
  margin-top: 5px;
  margin-bottom: 10px;

}


.weather .weekWeather{
  width:100%;
  position: relative;
  margin-top: 10px;

}

.weather .weekWeather .weekItem{
  float: left;
  width: 14%;
  /*border: 1px solid #000;*/
}

.weather .weekWeather .weekItem .weekItemTitle{
  font-size:12px;
}

.weather .weekWeather .weekItem .weekItemImg{
}

.weather .weekWeather .weekItem .weekItemText{
  font-size: 11px;
  margin-top: 0px;
  margin-bottom: 0px;
}



.weather .weekWeather .weekItem .weekItemImg .weekItemIcon{
  margin-top: 10px;
  width: 35%;
}


.weather .weekWeather .weekItem .weekItemTemp{
  margin-top : 10px;
  font-size: 12px;
}

.weather .weekWeather:after{ clear: both; display: block; content: '' }


</style>

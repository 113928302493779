<template v-if="loaded==true">
  <div class="schedule">
    <div class="schedul1Title" >
      &bull; 이번주의 일정
    </div>
    <div class="schedul1Contents">
      <table class="PublicTable">
        <template v-for="(item, i) in thisWeekDatas" v-bind:key="i">
          <tr>
            <td class="date">
              {{ item.evtDate }}
            </td>
            <td class="event" v-if="item.type == 1">
              {{ item.data.SUMMARY }}
            </td>
            <td class="eventComplete" v-if="item.type == 3">
              {{ item.data.SUMMARY }}
            </td>
          </tr>
        </template>
      </table>
    </div>
    <div class="schedul1Title" >
      &bull; 차주의 일정
    </div>
    <div class="schedul1Contents">
      <table class="PublicTable">
        <template v-for="(item, i) in nextWeekDatas" v-bind:key="i">
          <tr>
            <td class="date">
              {{ item.evtDate }}
            </td>
            <td class="event">
              {{ item.data.SUMMARY }}
            </td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import ical2json from "ical2json"

export default {
  name: 'TimeCompo',
  components: {
  },
  props: {},
  data(){
    return{
      loaded:false,
      toWeek: "",
      toNext:"",
      thisWeekDatas : [],
      nextWeekDatas : [],
    }
  },
  methods:{
    getThisWeek : function (){
      var value = [];
      var now = new Date();
      var nowDayOfWeek = now.getDay();
      var nowDay = now.getDate();
      var nowMonth = now.getMonth();
      var nowYear = now.getYear();
      nowYear += (nowYear < 2000) ? 1900 : 0;
      var weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
      var weekEndDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek));

      value.push(weekStartDate);
      value.push(weekEndDate);

      return value;
    },
    getNextWeek : function (){
      var value = [];
      var now = new Date();
      var nowDayOfWeek = now.getDay();
      var nowDay = now.getDate();
      var nowMonth = now.getMonth();
      var nowYear = now.getYear();
      nowYear += (nowYear < 2000) ? 1900 : 0;
      var weekStartDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek + 1));
      var weekEndDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek + 7));

      value.push(weekStartDate);
      value.push(weekEndDate);

      return value;
    },
    // isThisWeek: function (evtDate) {
    //   var cDate = evtDate.setHours(0, 0, 0, 0)
    //   var nowDate = new Date()
    //   nowDate.setHours(0, 0, 0, 0)
    //   if (this.toWeek[0] <= cDate && this.toWeek[1] >= cDate) {
    //     return true
    //   }
    //   return false;
    // },
    isDisplayWeek: function (evtDate) {
      var cDate = evtDate.setHours(0, 0, 0, 0)
      var nowDate = new Date()
      nowDate.setHours(0, 0, 0, 0)
      if (this.toWeek[0] <= cDate && this.toWeek[1] >= cDate) {
        if(nowDate <= cDate){
          return 1;
        }else{
          return 3;
        }
      }else if(this.toNext[0] <= cDate && this.toNext[1] >= cDate){
        return 2;
      }else{
        return false
      }
    },
    getDateFormat: function(evtDate){
      if( typeof(evtDate) != "string" ) return "";
      return  evtDate.substring(0,4) + "-" + evtDate.substring(4,6) + "-" + evtDate.substring(6,8);
    }
  },
  async created() {
    this.toWeek = this.getThisWeek()
    this.toNext = this.getNextWeek()
    // console.log(this.toNext)
    // this.startWeek = this.toWeek[0].getFullYear() + "." + (this.toWeek[0].getMonth()+1) + "." + this.toWeek[0].getDate()
    // this.endWeek = this.toWeek[1].getFullYear() + "." + (this.toWeek[1].getMonth()+1) + "." + this.toWeek[1].getDate()

    axios.get(this.$parent.serverURL + this.$parent.getURLEncode("https://calendar.google.com/calendar/ical/11s8nhehd78ned366lrfh8jk90%40group.calendar.google.com/private-249d6b522a2fc0a6853639580a328cb1/basic.ics"),
        {
        headers: {
        "Content-Type": "application/xml",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
      },
    })
    .then(response => {
      const any = require("ical2json");
      var icalJson = any.convert(response.data.replaceAll("DTSTART;VALUE=DATE", "DTSTART").replaceAll("DTEND;VALUE=DATE", "DTEND"));
      for(var i=0;i<50;i++){
        var dpWeek = this.isDisplayWeek(new Date(this.getDateFormat(icalJson.VCALENDAR[0].VEVENT[i].DTSTART)));
        if(dpWeek == 1){
          this.thisWeekDatas.push({evtDate: this.getDateFormat(icalJson.VCALENDAR[0].VEVENT[i].DTSTART), data: icalJson.VCALENDAR[0].VEVENT[i],type: dpWeek})
        }else if(dpWeek == 2){
          this.nextWeekDatas.push({evtDate: this.getDateFormat(icalJson.VCALENDAR[0].VEVENT[i].DTSTART), data: icalJson.VCALENDAR[0].VEVENT[i],type: dpWeek})
        }else if(dpWeek==3){
          this.thisWeekDatas.push({evtDate: this.getDateFormat(icalJson.VCALENDAR[0].VEVENT[i].DTSTART), data: icalJson.VCALENDAR[0].VEVENT[i],type: dpWeek})
        }


      }
      function compare(a, b) {
        if (a.evtDate < b.evtDate)
          return -1;
        if (a.evtDate > b.evtDate)
          return 1;
        return 0;
      }

      this.thisWeekDatas.sort(compare)
      this.nextWeekDatas.sort(compare)
    });


    this.loaded = true;
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.schedule{
  height: 400px;
}
.schedule .schedul1Title {
  font-size: 17px;

  margin-left: 10px;
  text-align: left;
  /*border-bottom: 3px #888;*/
  /*border-bottom-style: double ;*/
  /*width: 100px;*/
}

.schedule .schedul1Contents{
  padding-bottom: 50px;
  /*height:150px;*/
}

.schedule .schedul1Contents .PublicTable{
  margin-top: 15px;
  width: 100%;
  border-right: 1px solid #ccc;

}

.schedule .schedul1Contents .PublicTable .date {
}

.schedule .schedul1Contents .PublicTable .event {
  text-align: left;
}

.schedule .schedul1Contents .PublicTable .eventComplete {
  text-align: left;
  text-decoration: line-through;
  color:#ccc;
}



</style>
